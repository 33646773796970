import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconLinkedinOutlined: React.FC<PropsIcon> = ({
  width,
  height,
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7208 10.0005C10.4968 9.99542 10.274 10.0322 10.0655 10.1085C9.85693 10.1849 9.6669 10.2993 9.50656 10.445C9.34622 10.5908 9.21881 10.7649 9.13181 10.9572C9.04482 11.1495 9 11.3561 9 11.5648C9 11.7734 9.04482 11.98 9.13181 12.1723C9.21881 12.3646 9.34622 12.5387 9.50656 12.6845C9.6669 12.8302 9.85693 12.9446 10.0655 13.021C10.274 13.0973 10.4968 13.1341 10.7208 13.129C11.1593 13.1192 11.5763 12.9501 11.8827 12.6578C12.1891 12.3655 12.3606 11.9732 12.3606 11.5648C12.3606 11.1563 12.1891 10.764 11.8827 10.4717C11.5763 10.1794 11.1593 10.0103 10.7208 10.0005ZM19.5475 14.1531C18.1468 14.1531 17.2152 14.8677 16.8301 15.5467H16.7685V14.3524H14.0189V23H16.8907V18.7327C16.8907 17.6057 17.1312 16.5143 18.6316 16.5143C20.1096 16.5143 20.1291 17.7869 20.1291 18.79V23H23V18.2493C23 15.9245 22.4682 14.1531 19.5475 14.1531ZM9.3152 14.3524V23H12.2174V14.3524H9.3152Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1304 6.43478H7.86957C7.07716 6.43478 6.43478 7.07716 6.43478 7.86957V24.1304C6.43478 24.9228 7.07716 25.5652 7.86957 25.5652H24.1304C24.9228 25.5652 25.5652 24.9228 25.5652 24.1304V7.86957C25.5652 7.07716 24.9228 6.43478 24.1304 6.43478ZM7.86957 5C6.28475 5 5 6.28475 5 7.86957V24.1304C5 25.7153 6.28475 27 7.86957 27H24.1304C25.7153 27 27 25.7153 27 24.1304V7.86957C27 6.28475 25.7153 5 24.1304 5H7.86957Z"
        fill="white"
      />
    </svg>
  )
}
